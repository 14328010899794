.item {
    background-color: var(--black_color);
    border-radius: 20px;
    padding: 5px 20px 5px 5px;
    width: 100%;
    box-sizing: border-box;
    min-height: 110px;
    display: flex;
    gap: 20px;
    box-sizing: border-box;
    position: relative;

    min-height: 175px;
}

.itemBottom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 0;
    // margin-top: auto;
}

.boost {
    border: 1px solid var(--lalau_color);
    margin-bottom: 21px;
}

.left {
    width: 155px;
    border-radius: 15px;
    overflow: hidden;
    flex-shrink: 0;

    img {
        border-radius: 15px;
        overflow: hidden;
        height: 100%;
    }
}

.right {
    padding: 9px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.controls {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.totalLikes {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 5px;

    p {
        color: var(--lalau_color);
        font-size: 1.625rem;
        font-weight: 800;
        text-transform: uppercase;
    }
}

.percentBlock {
    display: flex;
    justify-content: space-between;
}

.userName {
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.percent {
    color: #91ff4e;
    font-weight: 400;
}

.boostTime {
    background-color: var(--lalau_color);
    padding: 2px 11px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 30px;
}

.winnerPrizeText {
    background-color: var(--lalau_color);
    padding: 5px 10px;
    border-radius: 40px;
    font-weight: 700 !important;
    font-size: 0.9rem !important;
    color: var(--white_color) !important;
    scale: 1.5;
}

.control {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    &:active {
        img {
            transition: 0.3s all linear;
            scale: 0.65;
        }
    }

    span {
        text-transform: uppercase;
        font-size: 0.6rem;
        display: flex;
        align-items: center;
        gap: 2px;
        line-height: 10px;

        small {
        }

        svg {
            vertical-align: middle;
            margin-bottom: 3px;
        }
    }
}

.stars {
    display: flex;
    gap: 6px;
    margin-top: 10px;
    cursor: pointer;

    span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;

        small {
            font-size: 0.875rem;
        }
    }

    svg {
    }
}

.inactiveStar {
    svg path {
        fill: #585c6b !important;
    }

    small {
        color: #585c6b !important;
    }
}

.controlBtns {
    display: flex;
    gap: 8px;

    .winnerPrizeText {
        scale: 1.2;
    }
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
