.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;

    h1 {
        font-size: 1.5rem;
    }

    small {
        font-size: 0.875rem;
        font-weight: 400;
        color: #c7c9d5;
        margin-top: 9px;
        max-width: 85%;
        line-height: 18px;

        b {
            font-weight: bold;
        }
    }
}

.right {
    display: flex;
    gap: 7px;
    align-items: center;
}

.contestEndDate {
    position: relative;

    span {
        opacity: 0.5;
    }
}

.endDateHint {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    padding: 7px 15px;
    background-color: var(--white_color);
    border-radius: 20px;
    font-size: 0.875rem;
    color: var(--light_black);
}

.dash {
    font-weight: bold;
    font-size: 1.2rem;
}

.dateBage {
    background-color: var(--light_black);
    padding: 12px 20px;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-direction: column;
    text-transform: uppercase;
    min-height: 60px;
    min-width: 50px;

    p {
        font-size: 2.5rem;
        font-weight: 700;
    }

    small {
        font-size: 1.25rem;
        font-weight: 400;
    }
}
