.wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    h2 {
        text-transform: uppercase;
        color: #ffcd4e;
        font-size: 1.5rem;
        font-weight: 800;
        text-align: center;
    }

    .img {
        display: block;
        margin: 0 auto;
    }

    .info {
        p {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 14px;
            color: var(--black_color);
        }
    }

    .controls {
        div {
            width: 100%;
        }
    }

    .starsList {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .starItem {
        background-color: #2e3346;
        border-radius: 10px;
        height: 40px;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 2px solid transparent;
        transition: 0.3s all;

        div {
            display: flex;
            align-items: center;
            gap: 10px;

            p {
                font-size: 0.75rem;
                font-weight: 600;
            }
        }

        &.selected {
            border: 2px solid #92acc7;
            transition: 0.3s all;
        }
    }

    .starItemDisable {
        cursor: default;

        div:first-child {
            filter: blur(2px);
        }
    }

    .starsHint {
        text-align: center;
        text-decoration: underline;
        color: var(--lalau_color) !important;
        cursor: pointer;
        font-size: 0.875rem;
    }

    .starsHintWrapper {
        line-height: 18px;
        font-size: 0.875rem;
        p {
            color: var(--white_color);
        }

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}
