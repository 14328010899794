.inactiveHistory {
    display: flex;
    justify-content: space-between;
    gap: 6px;
    padding-top: var(--page_y_padding);
    position: absolute;
    top: inherit;
    left: var(--page_x_padding);
    right: var(--page_x_padding);
    bottom: calc(100% - 287px) !important;
}

.left,
.right {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 30%;
    transition: 0.8s all;

    .skeletonItem,
    img {
        height: 180px;
    }
}

.left {
    margin-bottom: 0;

    .skeletonItem,
    img {
        height: 140px;
    }
}

.right {
}

.center {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: calc(40% - 2 * var(--page_x_padding));
    transition: 0.8s all;

    .skeletonItem,
    img {
        height: 250px;
    }
}
