.main {
    background-color: var(--white_color);
    padding: 30px 0 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 40px;
    position: relative;
}

.mainText {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.25rem;
    color: var(--black_color);
    margin-bottom: 2px;
}

.subText {
    font-size: 0.875rem;
    color: var(--black_color);
    font-weight: 400;
    text-align: left;
    width: 90%;
    line-height: 17px;
}

.top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 18px;
    width: 90%;
}

.topHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 10px;

    span {
        background-color: #4eb5ff1a;
        border-radius: 30px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #027bff;
        min-width: 120px;
        font-weight: 800;
        font-size: 1.25rem;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.item {
    border-radius: 10px;
    box-shadow: 0px 4px 14px 0px #00000026;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 13px;
    box-sizing: border-box;

    span {
        border: 1px solid var(--lalau_color);
        max-width: 110px;
        max-height: 34px;
        width: 100%;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            color: var(--lalau_color);
            text-transform: uppercase;
            font-size: 0.825rem;
            font-weight: 800;
            cursor: pointer;
        }

        svg path {
            fill: var(--lalau_color) !important;
        }
    }
}

.sponsors {
    display: flex;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.sponsorsTitle {
    color: var(--black_color);
    text-transform: uppercase;
    font-weight: 800;
}

.sponsorsWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

.moderation-btn {
    background-color: transparent;
    border-radius: 100px;
    color: var(--lalau_color);
    border: 1px solid var(--lalau_color);
    padding: 15px 13px;
    cursor: pointer;
    width: 300px;
    display: flex;
    box-sizing: border-box;
    text-decoration: none;
    justify-content: center;
    text-align: center;
    line-height: 18px;

    &.declined {
        color: #e94804;
        border-color: #e94804;
    }
}
