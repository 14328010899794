.wrapper {
    h4 {
        font-weight: 600;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin-bottom: 11px;
    }
}

.table {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footer {
    p {
        color: var(--blue_color);
        font-weight: 600;
        margin: 40px 0 20px 0;
        text-align: center;
        cursor: pointer;
    }
}
